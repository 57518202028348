exports.components = {
  "component---src-pages-black-2022-js": () => import("./../../../src/pages/black2022.js" /* webpackChunkName: "component---src-pages-black-2022-js" */),
  "component---src-pages-black-js": () => import("./../../../src/pages/black.js" /* webpackChunkName: "component---src-pages-black-js" */),
  "component---src-pages-box-js": () => import("./../../../src/pages/box.js" /* webpackChunkName: "component---src-pages-box-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jadlospis-js": () => import("./../../../src/pages/jadlospis.js" /* webpackChunkName: "component---src-pages-jadlospis-js" */),
  "component---src-pages-live-preview-details-tsx": () => import("./../../../src/pages/live-preview-details.tsx" /* webpackChunkName: "component---src-pages-live-preview-details-tsx" */),
  "component---src-pages-live-preview-tsx": () => import("./../../../src/pages/live-preview.tsx" /* webpackChunkName: "component---src-pages-live-preview-tsx" */),
  "component---src-pages-nowyrok-js": () => import("./../../../src/pages/nowyrok.js" /* webpackChunkName: "component---src-pages-nowyrok-js" */),
  "component---src-pages-nowyroktiktok-js": () => import("./../../../src/pages/nowyroktiktok.js" /* webpackChunkName: "component---src-pages-nowyroktiktok-js" */),
  "component---src-pages-polecam-js": () => import("./../../../src/pages/polecam.js" /* webpackChunkName: "component---src-pages-polecam-js" */),
  "component---src-pages-regulamin-konkursu-z-dabrowska-js": () => import("./../../../src/pages/regulamin-konkursu-z-dabrowska.js" /* webpackChunkName: "component---src-pages-regulamin-konkursu-z-dabrowska-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-start-js": () => import("./../../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */),
  "component---src-pages-temp-disabled-index-js": () => import("./../../../src/pages/temp-disabled-index.js" /* webpackChunkName: "component---src-pages-temp-disabled-index-js" */),
  "component---src-pages-tiktok-js": () => import("./../../../src/pages/tiktok.js" /* webpackChunkName: "component---src-pages-tiktok-js" */),
  "component---src-pages-zawsze-js": () => import("./../../../src/pages/zawsze.js" /* webpackChunkName: "component---src-pages-zawsze-js" */),
  "component---src-pages-zobacz-js": () => import("./../../../src/pages/zobacz.js" /* webpackChunkName: "component---src-pages-zobacz-js" */),
  "component---src-templates-page-builder-tsx": () => import("./../../../src/templates/pageBuilder.tsx" /* webpackChunkName: "component---src-templates-page-builder-tsx" */)
}

